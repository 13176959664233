export enum OpenGraphTypes {
  Article = 'article',
  Profile = 'profile',
  Product = 'product',
  Website = 'website'
}

export const OG_DEFAULT_IMAGE = {
  url: '/images/ext/legal-advice-middle-east-share.png',
  width: 1024,
  height: 512
};
